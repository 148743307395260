import {
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { imageDevelopmentCategory } from '../../../../../../api/property/types';
import ListItemMediaCountByCategory from './ListItemMediaCountByCategory';

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    horizontalList: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
  })
);

export default function DevelopmentMediaCount({ developmentMedias }) {
  const classes = useStyles();
  return (
    <>
      <ListItem>
        <ListItemText primary={'Development media count group by categories'} />
      </ListItem>
      <ListItem>
        <List disablePadding className={classes.horizontalList}>
          {Object.entries(developmentMedias).map(([category, mediaCount]) => (
            <ListItemMediaCountByCategory
              key={category}
              category={category}
              typeCategories={imageDevelopmentCategory}
              mediaCount={mediaCount}
            />
          ))}
        </List>
      </ListItem>
    </>
  );
}
