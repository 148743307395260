import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import pvtFetcher from '../utils/pvtFetcher';

interface FetcherResponse {
  data: {
    creationDate: Date;
  };
  meta: {
    developmentId: number;
    activityType: string;
  };
}

export default function useDevelopmentMetadataCreationDate({
  authToken,
  orgId,
  developmentId,
  queryConfig,
}: {
  authToken: string;
  orgId: number;
  developmentId: number;
  queryConfig?: UseQueryOptions<any>;
}) {
  const url = `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/metadata/creationDate`;

  return useQuery<FetcherResponse, Error>(
    [url, { authToken, orgId }],
    () => pvtFetcher(url, { authToken, orgId }),
    queryConfig
  );
}
