import * as React from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import useAuthToken from '../../../../../../store/auth/hooks/useAuthToken';
import {
  Box,
  createStyles,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import Layout from '../../../../../layout/CommonLayout/Layout';
import SEO from '../../../../../atoms/SEO';
import GenericPage from '../../../../../layout/CommonLayout/GenericPage';
import useDevelopment from '../../../../../../api/developments/useDevelopment';
import { Skeleton } from '@material-ui/lab';
import useDevelopmentMetadataCreationDate from '../../../../../../api/developments/useDevelopmentMetadataCreationDate';
import useDevelopmentMetadataDetailsWordCount from '../../../../../../api/developments/useDevelopmentMetadataDetailsWordCount';
import useDevelopmentMetadataMediaCount from '../../../../../../api/developments/useDevelopmentMetadataMediaCount';
import DevelopmentMediaCount from './DevelopmentMediaCount';
import FlatMediaCount from './FlatMediaCount';

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',

      maxWidth: theme.breakpoints.values.md,
      width: '100%',

      padding: `0 ${theme.spacing(2)}px`,
    },

    header: {
      padding: `${theme.spacing(2)}px 0`,
    },

    titleHeader: {
      paddingBottom: theme.spacing(2),
    },

    sectionHeader: {
      margin: `${theme.spacing(2)}px 0`,
    },

    list: {
      width: '100%',
    },

    horizontalList: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
    flexAutoWidth: {
      flexBasis: '33.333%',
    },
  })
);

export default function DevelopmentMetadataPage(props: RouteComponentProps) {
  const { orgId, developmentId } = useParams();
  const classes = useStyles();

  const authToken = useAuthToken();
  const privateParams = {
    authToken,
    orgId,
    developmentId,
  };
  const { data: developmentResponse, isLoading } =
    useDevelopment(privateParams);

  const { data: creationDateResponse } =
    useDevelopmentMetadataCreationDate(privateParams);

  const { data: detailWordCountResponse } =
    useDevelopmentMetadataDetailsWordCount(privateParams);

  const { data: developmentMediaCountResponse } =
    useDevelopmentMetadataMediaCount(privateParams);

  return (
    <Layout>
      <SEO title={'Development metadata'} />
      <GenericPage>
        <Box className={classes.ctn}>
          {isLoading || !developmentResponse ? (
            <Skeleton variant="rect" height={300} />
          ) : (
            <Box>
              <Typography className={classes.titleHeader} variant="h3">
                {developmentResponse.data.title}
              </Typography>

              <List
                className={classes.list}
                subheader={
                  <ListSubheader>
                    <Typography variant="h5">Development metadata</Typography>
                  </ListSubheader>
                }
              >
                <ListItem>
                  <ListItemText
                    primary={'Creation date'}
                    secondary={creationDateResponse?.data.creationDate}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={'Word count of development detail'}
                    secondary={`${detailWordCountResponse?.data.wordCount} words.`}
                  />
                </ListItem>
                {developmentMediaCountResponse?.data?.developmentMedias && (
                  <DevelopmentMediaCount
                    developmentMedias={
                      developmentMediaCountResponse?.data?.developmentMedias
                    }
                  />
                )}
                {developmentMediaCountResponse?.data?.flatMedias && (
                  <FlatMediaCount
                    flatMedias={developmentMediaCountResponse?.data?.flatMedias}
                  />
                )}
              </List>
            </Box>
          )}
        </Box>
      </GenericPage>
    </Layout>
  );
}
