import { OrgFileCategory } from '../orgs/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import pvtFetcher from '../utils/pvtFetcher';

interface FetcherResponse {
  data: {
    developmentMedias: Record<OrgFileCategory, number>;
    flatMedias?: Record<string, Record<OrgFileCategory, number>>;
  };
  meta: {
    developmentId: number;
  };
}

export default function useDevelopmentMetadataMediaCount({
  authToken,
  orgId,
  developmentId,
  queryConfig,
}: {
  authToken: string;
  orgId: number;
  developmentId: number;
  queryConfig?: UseQueryOptions<any>;
}) {
  const url = `${process.env.GATSBY_API_HOST}/api/development/${developmentId}/metadata/mediaCount?includeFlats=true`;

  return useQuery<FetcherResponse, Error>(
    [url, { authToken, orgId }],
    () => pvtFetcher(url, { authToken, orgId }),
    queryConfig
  );
}
