import {
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { imageFlatCategory } from '../../../../../../api/property/types';
import ListItemMediaCountByCategory from './ListItemMediaCountByCategory';

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    horizontalList: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
    flexAutoWidth: {
      flexBasis: '33.333%',
    },
  })
);

export default function FlatMediaCount({ flatMedias }) {
  const classes = useStyles();
  return (
    <>
      <ListItem>
        <ListItemText primary={'Flat media count group by categories'} />
      </ListItem>
      <ListItem>
        <List disablePadding className={classes.horizontalList}>
          {Object.entries(flatMedias).map(
            ([flatId, flatMedia]: [string, Record<string, number>]) => (
              <List
                disablePadding
                key={flatId}
                className={classes.horizontalList}
              >
                <ListItem>
                  <ListItemText primary={'Flat ID'} secondary={flatId} />
                </ListItem>
                {Object.entries(flatMedia).map(([category, mediaCount]) => (
                  <ListItemMediaCountByCategory
                    key={category}
                    category={category}
                    typeCategories={imageFlatCategory}
                    mediaCount={mediaCount}
                    dense
                    divider
                  />
                ))}
              </List>
            )
          )}
        </List>
      </ListItem>
    </>
  );
}
