import * as React from 'react';
import { Router } from '@reach/router';
import DevelopmentMetadataPage from '../../../components/pages/my-account/property/editDEPRECATED/development-metadata/DevelopmentMetadataPage';

export default function MyAccountPropertyIndexPage() {
  return (
    <Router basepath="/my-account/property">
      <DevelopmentMetadataPage path="/:orgId/:developmentId" />
    </Router>
  );
}
