import {
  createStyles,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexAutoWidth: {
      flexBasis: '20%',
    },
  })
);

export default function ListItemMediaCountByCategory({
  category,
  typeCategories,
  mediaCount,
  dense = false,
  divider = false,
}) {
  const classes = useStyles();
  return (
    <ListItem
      divider={divider}
      dense={dense}
      key={category}
      button
      className={classes.flexAutoWidth}
    >
      <ListItemText
        primary={typeCategories[category]}
        secondary={`${mediaCount} items.`}
      />
    </ListItem>
  );
}
